<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-2">
        <h6>Patient Numbers</h6>
      </div>
      <div class="cell small-12 medium-10">
        <p class="mb">
          This needs to be reviewed and completed for all indications you have selected.
        </p>
        <p class="mb">
          The <em>Population</em> will pull through from any selections you have made in the <em>Population</em> section. If you are modelling at an individual hospital department level and know the precise patient numbers for each indication, there is a <em>manual input</em> option. It is envisaged that this will only be used at an individual hospital department level. If you are modelling at a broader organisational level, it is not recommended that you use this option unless you have accurate patient numbers available.
        </p>
        <p class="mb">
          <em>Incidence & Prevalence.</em> Referenced default options have been included for each indication. These can be overwritten with your own assumptions should you have better local insight.
        </p>
        <p class="mb">
          <em>Number of new and switch patients.</em> These will be calculated based on the <em>population</em> and <em>incidence & prevalence</em> inputs.
        </p>
        <p class="mb">
          <em>Proportion of patients drug treated.</em> There is no default assumption here. If you know this information locally, please input it. There must be an input in this cell for the model to work.
        </p>
        <p class="mb">
          <em>Proportion of drug treated patients receiving ranibizumab.</em> There is no default assumption here. If you know this information locally, please input it. There must be an input in this cell for the model to work.
        </p>
        <p class="mb">
          <em>Average number of eyes affected.</em> The default is 1.0. This can be altered via the drop-down. The range is for 1.0 to 2.0 in increments of 0.1.
        </p>
        <p class="mb">
          Then click <em>NEXT</em>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstructionsOne',
};
</script>
