<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-2">
        <h6>Share</h6>
      </div>
      <div class="cell small-12 medium-10">
        <p class="mb"><strong>Share of ranibizumab - new patients (if selected)</strong></p>
        <p class="mb">There is one set of new patient share assumptions used across all indications.</p>
        <p class="mb">Enter the <em>current share</em> of new patients for each of the products you selected in the Drug / Timeframe section. The total must add up to 100%.</p>
        <p class="mb">In the <em>future scenario</em> section enter the share of new patients for each product that you aim to have in the future. The total must add up to 100%.</p>
        <p class="mb"><strong>Share of ranibizumab - switch patients (if selected)</strong></p>
        <p class="mb">There is one set of share assumptions for switch patients used across all indications.</p>
        <p class="mb">Enter the <em>current share</em> of switch patients for each of the products you selected in the Drug / Timeframe section. The total must add up to 100%.</p>
        <p class="mb">In the <em>future scenario</em> section enter the share of switch patients for each product that you aim to have in the future. The total must add up to 100%.</p>
        <p class="mb">In this section you also need to select the speed of implementation i.e., how quickly do you plan to have patients switched from their current product to their future product in year 1. This will affect that average number of patients on each treatment over the first year.</p>
        <p class="mb">Then click <em>NEXT</em></p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <hr class="short wide blue-line" />
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-2">
        <h6>Summary</h6>
      </div>
      <div class="cell small-12 medium-10">
        <p>
          You will see a summary based on all the data you have inputted. If you want a report generated showing this data please press the email report button.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstructionsOne',
};
</script>
