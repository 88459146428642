<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-2">
        <h6>Dosing</h6>
      </div>
      <div class="cell small-12 medium-10">
        <p class="mb">
          This screen includes default assumptions for Year 1 and - if selected - Subsequent Years of treatment. Each indication selected will need to have the dosing inputted.
        </p>
        <p class="mb">
          The default assumptions are based on the maximum dosing from the posology section of the product SmPC.
        </p>
        <p class="mb">
          <em>New Patients (if selected)</em>
        </p>
        <p class="mb">
          In the first year that they receive treatment New Patients are, on average, assumed to have received ½ a year of treatment. This is to allow for the fact that some new patients will start in month 1, whilst some will start treatment in month 12. In subsequent years (if selected) the maximum dosing of every 4 weeks means that patients can receive a maximum of 13 treatment cycles per annum.
        </p>
        <p class="mb">
          <em>Switch patients (if selected)</em>
        </p>
        <p class="mb">
          Later in the model you will be asked to select the speed at which switch patients are transferred to the new product. This will affect the average number of patients for each treatment in year 1. As with new patients, the maximum number of treatment cycles in subsequent years (if selected) is 13.
        </p>
        <p class="mb">
          <em>Changing the Default Assumptions</em>
        </p>
        <p class="mb">
          The default assumptions can be amended to reflect local guidelines and practice.
        </p>
        <p class="mb">
          Then click <em>NEXT</em>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstructionsOne',
};
</script>
