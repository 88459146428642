<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-2">
        <h6>Drug / Timeframe</h6>
      </div>
      <div class="cell small-12 medium-10">
        <p>
          There are four choices to be made here and your selections will turn blue:<br />
          <strong>Choice 1 –</strong> which drugs do you want to include in the model? Select between 1 and 3 drugs.<br />
          <strong>Choice 2 –</strong> which indications do you want to include in the model? Select each indication you wish to include or click all.<br />
          <strong>Choice 3 –</strong> what timeframe do you want to model? Select 1 year or 3 years.<br />
          <strong>Choice 4 –</strong> select the type of patient you wish to include in the model? Select New, Switch or Both.<br />
        </p>
        <p>Then click NEXT</p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <hr class="short wide blue-line" />
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-2">
        <h6>Cost</h6>
      </div>
      <div class="cell small-12 medium-10">
        <p class="mb">
          The NHS list price has been included for BYOOVIZ and LUCENTIS. Should you wish to include an alternative biosimilar in the model, you will need to input the list price. This can be sourced via the BNF.
        </p>
        <p class="mb">
          The model allows you to enter a discount off the list price or to include a local price.
        </p>
        <p class="mb">
          Please note that if you enter a price in the <em>'Local Price'</em> field the model will default to using this price.
        </p>
        <p class="mb">
          The <em>'Net Price'</em> cell will indicate the price being used in the model. Do not progress until you are happy that the model is using the price that you are happy with.
        </p>
        <p class="mb">
          Then click NEXT
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstructionsOne',
};
</script>
