<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-2">
        <h6>Instructions for use</h6>
      </div>
      <div class="cell small-12 medium-10">
        <p>Please see instructions for how to input data into this model. It is split into sections for ease of use. You do have the option to go back and update any part of the model by clicking on the bar at the top of the screen.</p>
        <p><strong>Please bear in mind if you request a report to be emailed to you or if you press FINISH you will exit the model and your data will NOT be retained. Please ensure you are finished before performing either of these functions.</strong></p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <hr class="short wide blue-line" />
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-2">
        <h6>Population</h6>
      </div>
      <div class="cell small-12 medium-10">
        <p>There are two options on how to select patient population for use in the model.</p>
        <p><strong>Option 1 - select</strong></p>
        <p>In this page you can select Country (England, Scotland, Wales or Northern Ireland), Region / Area / Health Board (NHS England Region / NI Health and Social Care Trust / Health Board in Scotland and Wales), and Organisation, (individual Integrated Care Board, Health and Social Care Trust or Health Board).</p>
        <p>Based on your selection, the population of the organisation will be selected. These population data have been sourced from NHSE, National Records of Scotland, Stats Wales and Northern Ireland Statistics & Research Agency. They are for the whole population in the locality and not patients. We will use incidence and prevalence data to calculate patient numbers.</p>
        <p>The selection you have made will appear at the bottom of the screen.</p>
        <p><strong><em>or</em></strong></p>
        <p><strong>Option 2 – input manually</strong></p>
        <p>If you wish to structure the model around an individual hospital rather than an ICB, HSCT or Health Board, you will need to enter the population covered by the hospital.</p>
        <p>In this case, enter the name of the hospital you wish to develop the model for and then enter the population covered by the hospital. The population to be entered is the whole population. We will use incidence and prevalence data to calculate patient numbers.</p>
        <p>Then click <em>NEXT</em></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstructionsOne',
};
</script>
